import * as mdb from 'mdb-ui-kit';
// import EcommerceGallery from './plugins/ecommerce-gallery/js/ecommerce-gallery';
// import MultiCarousel from './plugins/multi-carousel/js/multi-carousel';
// import Parallax from './plugins/parallax/js/parallax';

// import * as mdb from '../../From-Node_Modules/mdb-ui-kit/js/free/mdb.min';
// import * as mdb from '../../From-Node_Modules/mdb-ui-kit/js/mdb.min';
// import * as mdb from '../../From-Node_Modules/mdb-ui-kit/src/mdb/js/mdb.pro';

// import Calendar from './plugins/calendar/js/calendar';
// import Captcha from './plugins/captcha/js/captcha';
// import ColorPicker from './plugins/color-picker/js/color-picker';
// import Cookie from './plugins/cookie/js/cookie';
// import Countdown from './plugins/countdown/js/countdown';
// import DataParser from './plugins/data-parser/js/data-parser';
// import { Draggable, Sortable } from './plugins/drag-and-drop/js/drag-and-drop';
// import Dummy from './plugins/dummy/js/dummy';
// import FileUpload from './plugins/file-upload/js/file-upload';
// import Filters from './plugins/filters/js/filters';
// import Inputmask from './plugins/input-mask/js/input-mask';
// import Mention from './plugins/mention/js/mention';
// import Onboarding from './plugins/onboarding/js/onboarding';
// import OrganizationChart from './plugins/organization-chart/js/organization-chart';
// import ScrollStatus from './plugins/scroll-status/js/scroll-status';
// import Storage from './plugins/storage/js/storage';
// import TableEditor from './plugins/table-editor/js/table-editor';
// import Transfer from './plugins/transfer/js/transfer';
// import Treetable from './plugins/treetable/js/treetable';
// import Treeview from './plugins/treeview/js/treeview';
// import VectorMap from './plugins/vector-maps/js/vector-maps';
// import Wysiwyg from './plugins/wysiwyg/js/wysiwyg';

window.mdb = mdb;

export default {
    mdb,
    // MultiCarousel,
    // EcommerceGallery,
    // Parallax,
};
